<!-- Parameters -->
<div id="cad-parameters" class="card">
  <div class="card-header p-2 font-weight-bold">Paramètres</div>
  <div class="card-body p-2">
    <div class="form-group">
      <label for="opacity">Transparence</label>
      <input type="number" class="form-control" id="opacity" min="0" max="100" placeholder="Opacité" step="5" [(ngModel)]="cad.version.mapOpacity" (ngModelChange)="cad.setOpacity(cad.version.mapOpacity)">
    </div>

    <div class="form-group">
      <label for="iconsSize">Taille des régies</label>
      <input type="number" class="form-control" id="iconControlSize" min="1" max="50" placeholder="Taille des régies" [(ngModel)]="cad.version.iconControlSize" (ngModelChange)="cad.setIconControlSize(cad.version.iconControlSize)">
      <label for="iconsSize">Taille des points élèctriques</label>
      <input type="number" class="form-control" id="iconElectricNodeSize" min="1" max="50" placeholder="Taille des points élèctriques" [(ngModel)]="cad.version.iconElectricNodeSize" (ngModelChange)="cad.setIconElectricNodeSize(cad.version.iconElectricNodeSize)">
      <label for="iconsSize">Taille des icônes</label>
      <input type="number" class="form-control" id="iconsSize" min="1" max="50" placeholder="Taille des icônes" [(ngModel)]="cad.version.iconsSize" (ngModelChange)="cad.setIconsSize(cad.version.iconsSize)">
    </div>

    <div class="form-group">
      <label for="iconsSize">Distance des pièges par défaut</label>
      <input type="number" class="form-control" min="1" max="50" step=".5" placeholder="Distance des pièges" [(ngModel)]="cad.drawDistance">
    </div>
  </div>
</div>