import { Component, ViewChild, ElementRef, OnInit, HostListener } from '@angular/core';
import * as FileSaver from 'file-saver';
import { CADComponent } from '../cad.component';
import { CADCanvasComponent } from '../canvas/canvas.component';

@Component({
  selector: 'cad-clip-canvas',
  styleUrls: [ './clip-canvas.component.css' ],
  templateUrl: './clip-canvas.component.html'
})
export class ClipCanvasComponent implements OnInit {
  private canvasComponent: CADCanvasComponent;
  private oldWidth;
  private oldHeight;
  private oldTranslatePosX;
  private oldTranslatePosY;

  // Clip
  @ViewChild('clipCanvas', { static: false }) private clipCanvas: ElementRef;
  @ViewChild('clipRect', { static: false }) private clipRect: ElementRef;
  @ViewChild('range', { static: false }) range: ElementRef;

  private legendWidthPixels: number = 0;
  private translatePosition: any = { x: 0, y: 0 };
  private startDragOffset: any = { x: 0, y: 0 };

  private tmpBackgroundImage;
  private tmpProtectedAreaImage;
  private tmpBeltsImage;
  private tmpElectricImage;

  private offset;

  constructor() {}

  ngOnInit() {
    // Init canvas
    this.clipCanvas.nativeElement.width = document.getElementById('CAD').offsetWidth - 35;
    this.clipCanvas.nativeElement.height = document.getElementById('CAD').offsetHeight - 135;
  }

  init(canvasComponent: CADCanvasComponent) {
    this.canvasComponent = canvasComponent;

    /// Render
    // Change canvas sizes
    this.oldWidth = canvasComponent.backgroundImageCanvas.nativeElement.width;
    this.oldHeight = canvasComponent.backgroundImageCanvas.nativeElement.height;
    var newWidth = this.oldWidth * canvasComponent.cad.renderFactor,
        newHeight = this.oldHeight * canvasComponent.cad.renderFactor;
    canvasComponent.cad.render = true;
    // Change translatePos
    this.oldTranslatePosX = canvasComponent.cad.translatePosition.x;
    this.oldTranslatePosY = canvasComponent.cad.translatePosition.y;
    canvasComponent.cad.translatePosition.x = this.oldTranslatePosX * canvasComponent.cad.renderFactor;
    canvasComponent.cad.translatePosition.y = this.oldTranslatePosY * canvasComponent.cad.renderFactor;
    // Background Image
    canvasComponent.backgroundImageCanvas.nativeElement.width = newWidth;
    canvasComponent.backgroundImageCanvas.nativeElement.height = newHeight;
    canvasComponent.drawBackgroundImage();
    // Protected areas
    canvasComponent.protectedAreasCanvas.nativeElement.width = newWidth;
    canvasComponent.protectedAreasCanvas.nativeElement.height = newHeight;
    canvasComponent.drawProtectedAreas();
    // Belts
    canvasComponent.beltsCanvas.nativeElement.width = newWidth;
    canvasComponent.beltsCanvas.nativeElement.height = newHeight;
    canvasComponent.drawBelts();
    // Electric
    canvasComponent.electricCanvas.nativeElement.width = newWidth;
    canvasComponent.electricCanvas.nativeElement.height = newHeight;
    canvasComponent.drawElectric();

    // Generate temp images from canvas
    this.tmpBackgroundImage = new Image();
    this.tmpBackgroundImage.onload = () => { this.draw() }
    this.canvasComponent.backgroundImageCanvas.nativeElement.toBlob(blob => {
      this.tmpBackgroundImage.src = URL.createObjectURL(blob);
      this.unRender();
    });

    this.tmpProtectedAreaImage = new Image();
    this.tmpProtectedAreaImage.onload = () => { this.draw() }
    this.canvasComponent.protectedAreasCanvas.nativeElement.toBlob(blob => {
      this.tmpProtectedAreaImage.src = URL.createObjectURL(blob);
      this.unRender();
    });

    this.tmpBeltsImage = new Image();
    this.tmpBeltsImage.onload = () => { this.draw() }
    this.canvasComponent.beltsCanvas.nativeElement.toBlob(blob => {
      this.tmpBeltsImage.src = URL.createObjectURL(blob);
      this.unRender();
    });

    this.tmpElectricImage = new Image();
    this.tmpElectricImage.onload = () => { this.draw() }
    this.canvasComponent.electricCanvas.nativeElement.toBlob(blob => {
      this.tmpElectricImage.src = URL.createObjectURL(blob);
      this.unRender();
    });

    // Canvas size
    this.clipCanvas.nativeElement.height = document.getElementById('clip-canvas').parentElement.clientHeight * canvasComponent.cad.renderFactor;
    this.clipCanvas.nativeElement.width = document.getElementById('clip-canvas').parentElement.clientWidth * canvasComponent.cad.renderFactor;
    this.translatePosition.x = this.clipCanvas.nativeElement.width / 2;
    this.translatePosition.y = this.clipCanvas.nativeElement.height / 2;

    this.draw();
    this.initClipRect();
  }

  initClipRect() {
    const element: any = document.querySelector('.resizable');
    const resizers = document.querySelectorAll('.resizable' + ' .resizer')
    let original_width = 0;
    let original_height = 0;
    let original_x = 0;
    let original_y = 0;
    let original_mouse_x = 0;
    let original_mouse_y = 0;
    for (let i = 0;i < resizers.length; i++) {
      const currentResizer = resizers[i];
      currentResizer.addEventListener('mousemove', (e: any) => {
        if (e.which == 1)
          this.draw();
      });
      currentResizer.addEventListener('mousedown', (e: any) => {
        e.preventDefault()
        original_width = parseFloat(getComputedStyle(element, null).getPropertyValue('width').replace('px', ''));
        original_height = parseFloat(getComputedStyle(element, null).getPropertyValue('height').replace('px', ''));
        original_x = element.getBoundingClientRect().left;
        original_y = element.getBoundingClientRect().top;
        original_mouse_x = e.pageX;
        original_mouse_y = e.pageY;

        window.addEventListener('mousemove', resize)
        window.addEventListener('mouseup', stopResize)

        function resize(e) {
          if (currentResizer.classList.contains('bottom-right')) {
            element.style.width = original_width + (e.pageX - original_mouse_x)  + 'px'
            element.style.height = original_height + (e.pageY - original_mouse_y)  + 'px'
          }
          else if (currentResizer.classList.contains('bottom-left')) {
            element.style.width = original_width - (e.pageX - original_mouse_x)  + 'px'
            element.style.height = original_height + (e.pageY - original_mouse_y)  + 'px'
            element.style.left = original_x + (e.pageX - original_mouse_x) - 35 + 'px'
          }
          else if (currentResizer.classList.contains('top-right')) {
            element.style.width = original_width + (e.pageX - original_mouse_x)  + 'px'
            element.style.height = original_height - (e.pageY - original_mouse_y)  + 'px'
            element.style.top = original_y + (e.pageY - original_mouse_y) - 80 + 'px'
          }
          else {
            element.style.width = original_width - (e.pageX - original_mouse_x)  + 'px'
            element.style.height = original_height - (e.pageY - original_mouse_y)  + 'px'
            element.style.top = original_y + (e.pageY - original_mouse_y) - 80 + 'px'
            element.style.left = original_x + (e.pageX - original_mouse_x) - 35 + 'px'
          }
        }
        
        function stopResize() {
          window.removeEventListener('mousemove', resize)
        }
      })
    }
  }

  draw() {
    var context = this.clipCanvas.nativeElement.getContext('2d');
    context.clearRect(0, 0, this.clipCanvas.nativeElement.width, this.clipCanvas.nativeElement.height);
    context.save();

    // Quality
    context.webkitImageSmoothingEnabled = false;
    context.mozImageSmoothingEnabled = false;
    context.imageSmoothingEnabled = false;

    context.translate(this.translatePosition.x, this.translatePosition.y);

    // Draw canvas
    if (this.tmpBackgroundImage)
      context.drawImage( this.tmpBackgroundImage, - this.tmpBackgroundImage.width / 2, - this.tmpBackgroundImage.height / 2);

    if (this.tmpProtectedAreaImage)
      context.drawImage(this.tmpProtectedAreaImage, - this.tmpProtectedAreaImage.width / 2, - this.tmpProtectedAreaImage.height / 2);

    if (this.tmpBeltsImage)
      context.drawImage(this.tmpBeltsImage, - this.tmpBeltsImage.width / 2, - this.tmpBeltsImage.height / 2);

    if (this.tmpElectricImage)
      context.drawImage(this.tmpElectricImage, - this.tmpElectricImage.width / 2, - this.tmpElectricImage.height / 2);

    // Draw clip rectangle
    var pTopLeft        = { x: this.clipRect.nativeElement.offsetLeft * 3, y: this.clipRect.nativeElement.offsetTop * 3 },
        pBotRight       = { x: this.clipRect.nativeElement.offsetLeft * 3 + this.clipRect.nativeElement.offsetWidth * 3, y: this.clipRect.nativeElement.offsetTop * 3 + this.clipRect.nativeElement.offsetHeight * 3 };

    context.translate(- this.translatePosition.x, - this.translatePosition.y);

    // Draw legend
    this.drawLegend(context, this.clipRect.nativeElement.offsetLeft * 3 + this.clipRect.nativeElement.offsetWidth * 3, this.clipRect.nativeElement.offsetTop * 3 + this.clipRect.nativeElement.offsetHeight * 3);

    context.restore();
  }

  drawLegend(context, translateX, translateY) {
    context.save();
    context.translate(translateX, translateY);
    context.scale(this.canvasComponent.cad.renderFactor, this.canvasComponent.cad.renderFactor);

    var height = 20,
        border = 2,
        margin = 10,
        text = this.range.nativeElement.value + ' m';

    // Get distance in pixels
    var x = 0,
        distance = 0,
        coords1 = this.canvasComponent.cad.getCoordinates(0, 0);
        
    while (distance < this.range.nativeElement.value) {
      x += 1;
      var coords2 = this.canvasComponent.cad.getCoordinates(x, 0);           // Get coords
      var distance = this.canvasComponent.cad.getDistance(coords1, coords2); // Get distance
    }
    this.legendWidthPixels = this.canvasComponent.cad.distance(0, 0, x, 0) * this.canvasComponent.cad.version.zoom;

    context.font = "12px Arial";
    context.fillStyle = 'grey';
    context.strokeStyle = '#ffffff';
    context.lineWidth = border;
    
    context.beginPath();
    context.rect(- this.legendWidthPixels - margin * 2 - border, - height - margin * 2 - border, this.legendWidthPixels + margin * 2, height + margin * 2);
    context.fill();
    
    context.moveTo(- margin, - height / 2 - margin);
    context.lineTo(- this.legendWidthPixels - margin, - height / 2 - margin);
    context.moveTo(- margin, - height - margin);
    context.lineTo(- margin, - margin);
    context.moveTo(- this.legendWidthPixels - margin, - height - margin);
    context.lineTo(- this.legendWidthPixels - margin, - margin);
    context.stroke();

    context.fillStyle = '#fff';
    context.fillText(text, - margin - this.legendWidthPixels / 2 - context.measureText(text).width / 2, - height / 1.5 - margin);

    context.restore();
  }

  download() {
    function generateCanvas(clipRect, image) {
      var clippedCanvas = document.createElement('canvas');
      clippedCanvas.id = 'clipped-canvas';
        
      var clippedContext = clippedCanvas.getContext('2d');
      clippedCanvas.width = clipRect.nativeElement.offsetWidth * 3 * 2;
      clippedCanvas.height = clipRect.nativeElement.offsetHeight * 3 * 2;
      clippedContext.clearRect(0, 0, clippedCanvas.width, clippedCanvas.height); // Clear canvas
      clippedContext.save();

      // Quality
      clippedContext.imageSmoothingEnabled = true;

      clippedContext.scale(2, 2);

      /* Draw image */
      // Params
      var sourceX = clipRect.nativeElement.offsetLeft * 3,
          sourceY = clipRect.nativeElement.offsetTop * 3,
          sourceWidth = clipRect.nativeElement.offsetWidth * 3,
          sourceHeight = clipRect.nativeElement.offsetHeight * 3,
          canvasX = 0,
          canvasY = 0,
          canvasWidth = clipRect.nativeElement.offsetWidth * 3,
          canvasHeight = clipRect.nativeElement.offsetHeight * 3;

      // Draw
      clippedContext.drawImage(image, sourceX, sourceY, sourceWidth, sourceHeight, canvasX, canvasY, canvasWidth, canvasHeight);

      clippedContext.restore();

      // Save image
      clippedCanvas.toBlob(blob => {
        FileSaver.saveAs(blob, "export.jpeg");
        clippedCanvas.remove();
      }, "image/jpg", 1.0)
    }

    // Get image from clip canvas
    var image = new Image();
    image.src = this.clipCanvas.nativeElement.toDataURL('image/jpg', 1.0);
    image.onload = () => {
      generateCanvas(this.clipRect, image)
    }
  }

  unRender() {
    if (this.tmpBackgroundImage && this.tmpProtectedAreaImage && this.tmpBeltsImage && this.tmpElectricImage) {
      /// Unrender
      // Restore translatePos
      this.canvasComponent.cad.translatePosition.x = this.oldTranslatePosX;
      this.canvasComponent.cad.translatePosition.y = this.oldTranslatePosY;
      // Restore canvas sizes
      this.canvasComponent.cad.render = false;
      // Background Image
      this.canvasComponent.backgroundImageCanvas.nativeElement.width = this.oldWidth;
      this.canvasComponent.backgroundImageCanvas.nativeElement.height = this.oldHeight;
      this.canvasComponent.drawBackgroundImage();
      // Protected areas
      this.canvasComponent.protectedAreasCanvas.nativeElement.width = this.oldWidth;
      this.canvasComponent.protectedAreasCanvas.nativeElement.height = this.oldHeight;
      this.canvasComponent.drawProtectedAreas();
      // Belts
      this.canvasComponent.beltsCanvas.nativeElement.width = this.oldWidth;
      this.canvasComponent.beltsCanvas.nativeElement.height = this.oldHeight;
      this.canvasComponent.drawBelts();
      // Electric
      this.canvasComponent.electricCanvas.nativeElement.width = this.oldWidth;
      this.canvasComponent.electricCanvas.nativeElement.height = this.oldHeight;
      this.canvasComponent.drawElectric();
    }
  }

  close() {
    this.canvasComponent.cad.dowloadImage = false;
  }

  // Resize Event
  @HostListener('window:resize', ['$event'])
  onResize(e) {
    this.draw();
  }

  // ClipRect events
  cliRectChange(e) {
    this.draw();
  }

}